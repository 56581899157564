"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.schema = void 0;
var schema = function (defaults) {
    return Object.keys(defaults).reduce(function (memo, key) { return __spreadArray(__spreadArray([], memo), [
        { param: key, default: defaults[key], type: typeof defaults[key] }
    ]); }, []);
};
exports.schema = schema;
