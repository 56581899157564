"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.coerce = exports.coerceType = exports.intoType = void 0;
var is_1 = require("./is");
var coerceNumber = function (v) { return +v; };
var coerceString = function (v) { return "" + v; };
var coerceBoolean = function (v) {
    if (v === "true" || v === true)
        return true;
    return false;
};
var typeOf = function (input) {
    return typeof input === "object" && is_1.isArray(input) ? "array" : typeof input;
};
var intoType = function (v, hint) {
    switch (typeOf(hint)) {
        case "number":
            return coerceNumber(v);
        case "string":
            return coerceString(v);
        case "boolean":
            return coerceBoolean(v);
        case "array":
            return v.map(function (x, i) { return exports.intoType(x, hint[i]); });
    }
    // Falls through
    return null;
};
exports.intoType = intoType;
var coerceType = function (v) {
    if (is_1.isArray(v))
        return v.map(exports.coerceType);
    switch (true) {
        case v === "true" || v === true: // Boolean `true`
            return true;
        case v === "false" || v === false: // Boolean `false`
            return false;
        case v === "null" || v === null: // `null`
            return null;
        case +v == v: // Number
            return coerceNumber(v);
        default:
            return v;
    }
};
exports.coerceType = coerceType;
var coerce = function (input, hints, 
/**
 * `strictArrays` enforces type AND length
 */
options) {
    if (hints === void 0) { hints = {}; }
    if (options === void 0) { options = { strictArrays: false }; }
    return Object.keys(input).reduce(function (memo, key) {
        var v = input[key];
        if (typeOf(hints[key]) === "array" && !options.strictArrays) {
            memo[key] = exports.coerceType(v);
        }
        else {
            memo[key] =
                hints[key] !== undefined ? exports.intoType(v, hints[key]) : exports.coerceType(v);
        }
        return memo;
    }, {});
};
exports.coerce = coerce;
