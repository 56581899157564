"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.enforce = void 0;
var is_1 = require("./is");
var enforce = function (nextOptions, defaults) {
    if (is_1.isEmpty(nextOptions) || is_1.isEmpty(defaults))
        return;
    Object.keys(nextOptions).forEach(function (key) {
        if (is_1.isNull(defaults[key]) || is_1.isNull(nextOptions[key]))
            return;
        if (!(typeof defaults[key] === typeof nextOptions[key])) {
            throw new Error(key + " should be " + typeof defaults[key]);
        }
    });
};
exports.enforce = enforce;
