"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isArray = exports.isNull = exports.isEmpty = void 0;
var isEmpty = function (x) {
    if (!x)
        return true;
    return Object.keys(x).length === 0 && x.constructor === Object;
};
exports.isEmpty = isEmpty;
var isNull = function (x) { return x === null; };
exports.isNull = isNull;
exports.isArray = Array.isArray;
