"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.configure = void 0;
var params_1 = require("./lib/params");
var schema_1 = require("./lib/schema");
var encode_1 = require("./lib/encode");
var util_1 = require("./lib/util");
var parse_1 = require("./lib/parse");
var configure = function (defaults, queryString) {
    var encode = function (nextParams) {
        return encode_1.encode(nextParams, defaults);
    };
    var reconfigure = function (nextParams) {
        return util_1.redirect(encode(nextParams));
    };
    var query = parse_1.parse(queryString !== null && queryString !== void 0 ? queryString : util_1.getQueryString());
    return {
        defaults: defaults,
        query: query,
        params: params_1.params(defaults, query),
        schema: schema_1.schema(defaults),
        encode: encode,
        reconfigure: reconfigure,
    };
};
exports.configure = configure;
exports.default = exports.configure;
