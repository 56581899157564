"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.params = void 0;
var parse_1 = require("./parse");
var coerce_1 = require("./coerce");
var util_1 = require("./util");
var params = function (defaults, query) {
    var options = coerce_1.coerce(parse_1.parse(query !== null && query !== void 0 ? query : util_1.getQueryString()), defaults);
    return __assign(__assign({}, (defaults ? defaults : {})), options);
};
exports.params = params;
