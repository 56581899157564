"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.encode = void 0;
var qs_1 = require("qs");
var enforce_1 = require("./enforce");
var encode = function (nextOptions, defaults) {
    if (defaults)
        enforce_1.enforce(nextOptions, defaults);
    return qs_1.stringify(__assign(__assign({}, (defaults !== null && defaults !== void 0 ? defaults : {})), nextOptions));
};
exports.encode = encode;
