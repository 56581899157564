"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parse = void 0;
var qs_1 = require("qs");
var parse = function (input) {
    if (input !== undefined && typeof input === "string" && input[0] === "?") {
        input = input.slice(1);
    }
    return qs_1.parse(input);
};
exports.parse = parse;
