"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.redirect = exports.getQueryString = void 0;
var getQueryString = function () {
    return typeof window === "undefined" ? "" : window.location.search.slice(1);
};
exports.getQueryString = getQueryString;
var redirect = function (querystring) {
    window.location.search = "?" + querystring;
};
exports.redirect = redirect;
